<template>
  <div>
    <div>
      <addNode :nameValueNull="nameValueNull"></addNode>
      <div v-for="(item, index) in ApprovalData" :key="index">
        <div>
          <div style="display: flex">
            <div class="box" @click="setRoles(item)">
              <div class="div1">
                <span class="span1">{{ item.flowNodeName }}</span>
                <span class="span2"
                  ><i class="el-icon-close" @click.stop="delNode(index)"></i
                ></span>
              </div>
              <div class="div2">
                <span
                  class="span1"
                  style="color: grey"
                  v-if="!item.flowNodeNote"
                  >请选择{{ item.flowNodeName }}</span
                >
                <span class="span1" v-else>
                  {{ item.flowNodeNote }}
                </span>
                <span class="span2">
                  <i class="el-icon-arrow-right"></i>
                </span>
              </div>
            </div>
            <div class="divtwo" v-if="item.Iferror">
              <i class="el-icon-warning" style="color: rgb(242, 86, 67)"></i>
            </div>
          </div>
          <addNode :childNodeIndex="index + 1"></addNode>
        </div>
        <!-- 具体内容-->
        <div>
          <el-drawer
            append-to-body
            :visible.sync="item.Ifdrawer"
            direction="rtl"
            custom-class="demo-drawer"
            :show-close="false"
          >
            <div slot="title" class="title">
              <span>{{ item.flowNodeName }}</span>
            </div>
            <div><el-divider></el-divider></div>
            <!-- 审批人 -->
            <div class="demo-drawer__content">
              <div v-if="flowNodeName === '审批人'">
                <el-tabs v-model="activeTypeName">
                  <el-tab-pane label="节点类型" name="first" disabled>
                    <!-- <el-radio-group v-model="nodeType">
                      <el-radio
                        v-for="item1 in nodeTypeList"
                        :key="item1.value"
                        :label="item1.value"
                      >
                        {{ item1.label }}
                        <span class="mark">({{ item1.mark }})</span>
                      </el-radio>
                    </el-radio-group> -->
                  </el-tab-pane>
                  <el-tab-pane label="审批人类型" name="second">
                    <div v-if="nodeType !== '3'">
                      <el-radio-group
                        v-model="childnodeType"
                        @change="setchildnodeRadio"
                      >
                        <el-radio label="role">审批角色</el-radio>
                        <el-radio label="user" disabled>审批用户</el-radio>
                      </el-radio-group>
                      <div style="margin-top: 20px">
                        <el-form
                          ref="ChildNodeform"
                          :model="ChildNodeform"
                          label-width="90px"
                        >
                          <div v-if="childnodeType === 'role'">
                            <el-form-item
                              label="审批角色："
                              prop="role"
                              class="ELselect1"
                            >
                              <ELselect
                                v-model="ChildNodeform.role"
                                :options="roleOptions"
                                :optionKey="roleOptionsKeys"
                                Splaceholder="请选择审批角色"
                              ></ELselect>
                            </el-form-item>
                          </div>
                        </el-form>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>

              <div class="demo-drawer__footer">
                <el-button @click="cancleApprover(item)">取 消</el-button>
                <el-button type="primary" @click="saveApprover(item)"
                  >确 定</el-button
                >
              </div>
            </div>
          </el-drawer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import addNode from '@/components/Home/cloudmarket/approvalmanager/workflow/addNode.vue'
import ListenEvent from '@/libs/ListenEvent.js'
import ELselect from '@/components/myself/el_select.vue'
import { getRoleALL } from '@/api/systemanager/role'


export default {
  components: {
    addNode, ELselect
  },
  props: {
    nameValueNull: {
      type: String, Boolean
    },
  },
  data () {
    return {
      flowNodeName: '',
      ApprovalData: [],
      activeTypeName: 'second',
      nodeType: '1',
      nodeTypeList: [{
        value: '1',
        label: '或签审批',
        mark: '任意一名审批人同意，该审批节点即通过',

      },
      {
        value: '2',
        label: '会签审批',
        mark: '需要所有审批人同意，该审批节点才通过',
      },
      {
        value: '3',
        label: '逐级审批',
        mark: '按人事部门层级逐级依次审批后，该审批节点才通过',
      }],
      childnodeType: 'role',
      ChildNodeform: {
        user: '',
        role: ''
      },

      userOptions: [],
      userOptionsKeys: {
        value: 'id',
        label: 'name',
        label2: ''
      },
      roleOptions: [],
      roleOptionsKeys: {
        value: 'multiValue',
        label: 'name',
        label2: ''
      },

      // 
      timer: false,
    }
  },
  mounted () {
    this.Eventall()
    this.getRoleList()
  },
  beforeDestroy () {
    ListenEvent.$off('addTypeValue')
  },
  methods: {
    Eventall () {
      ListenEvent.$on('addTypeValue', (para) => {
        this.flowNodeName = para[0]

        // 特定位置插入
        // this.ApprovalData.splice(para[1], 0, { flowNodeName: this.flowNodeName, id: this.NodeID, flowNodeNote: '', Ifdrawer: true })
        this.ApprovalData.splice(para[1], 0, { flowNodeName: this.flowNodeName, flowNodeNote: '', flowType: '', roleName: '', roleId: '', Ifdrawer: true, Iferror: false })

      })
      ListenEvent.$on('SaveFlow', () => {
        ListenEvent.$emit('SaveFlowValue', this.ApprovalData)
      })

      ListenEvent.$on('showFlow', (para) => {
        this.ApprovalData = para
      })
    },
    async getRoleList () {
      const res = await getRoleALL()
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.roleOptions = res.data.data
    },
    setRoles (para) {
      this.flowNodeName = para.flowNodeName
      this.$set(para, 'Ifdrawer', true)
    },
    cancleApprover (para) {
      this.$set(para, 'Ifdrawer', false)
      if (!para.flowNodeNote.length) this.$set(para, 'Iferror', true)
    },
    saveApprover (para) {
      // let NodeValuetype = ''
      // if (this.nodeType == '1') NodeValuetype = '或签'
      // else if (this.nodeType == '2') NodeValuetype = '会签'
      // else NodeValuetype = '审批'

      // // 提示信息：
      // let strs = ''
      // if (this.ChildNodeform.role.length) {
      //   strs = '由角色:' + this.ChildNodeform.role + NodeValuetype
      // } else if (this.ChildNodeform.user.length) {
      //   strs = '由用户:' + this.ChildNodeform.user + NodeValuetype
      // }

      let strs = ''
      if (this.ChildNodeform.role.length) {
        strs = '由角色' + this.ChildNodeform.role.split(',')[1] + '审批'
      }
      if (!strs.length) return this.$message.error('请选择审批数据')
      this.$set(para, 'flowNodeNote', strs)
      this.$set(para, 'roleName', this.ChildNodeform.role.split(',')[1])
      this.$set(para, 'roleId', this.ChildNodeform.role.split(',')[0])
      this.$set(para, 'Ifdrawer', false)
      this.$set(para, 'Iferror', false)


    },
    setchildnodeRadio () {
      if (this.childnodeType === 'role') {
        this.ChildNodeform.user = ''
      } else if (this.childnodeType === 'user') {
        this.ChildNodeform.role = ''
      }
    },

    delNode (index) {
      this.ApprovalData.splice(index, 1)
    },
  }
}
</script>
<style lang="less" scoped>
.box {
  height: 100px;
  margin-top: 10px;
  .div1 {
    background-color: rgb(230, 162, 60);
    width: 280px;
    height: 40px;
    line-height: 40px; /*让黄色div中的文字内容垂直居中*/
    text-align: left; /*让文字水平居中*/
    font-size: 14px;
    border-radius: 4px;
    font-weight: 700;
  }
  .div2 {
    background-color: rgb(241, 240, 237);
    width: 280px;
    height: 60px;
    line-height: 60px; /*让黄色div中的文字内容垂直居中*/
    text-align: left; /*让文字水平居中*/
    font-size: 14px;
    border-radius: 4px;
  }
  .span1 {
    margin-left: 20px;
  }
  .span2 {
    float: right;
    margin-right: 20px;
  }
}

.divtwo {
  height: 100px;
  line-height: 100px;
  font-size: 20px;
}

//
/deep/ .el-drawer__header {
  margin-bottom: 0;
}
/deep/ .el-tabs__item {
  font-size: 18px;
}
/deep/ .el-radio__label {
  font-size: 16px;
}

.demo-drawer {
  .title {
    font-size: 25px;
    color: rgb(19, 17, 17);
    justify-content: flex-start;
  }

  .demo-drawer__content {
    padding: 20px;

    .el-radio {
      margin-bottom: 16px;
      margin-top: 20px;

      .mark {
        color: #828282;
      }

      &.is-checked {
        .mark {
          color: #4880ff;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .demo-drawer__footer {
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px;
    justify-content: flex-end;
    background: #fff;
    width: 100%;
    border-top: 1px solid #ebebeb;
    z-index: 2;
  }
}

.ELselect1 {
  width: 70%;
}
</style>