<template>
  <div>
    <el-tooltip class="item" effect="dark" content="回到顶部" placement="top">
      <el-button
        type="primary"
        circle
        class="backtop"
        v-show="Topshow"
        @click="toUp"
        ><i class="el-icon-caret-top"></i
      ></el-button>
    </el-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    intemIndex: {
      typr: String,
      default: ''
    }
  },
  data () {
    return {
      Topshow: false,
    }
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 回到顶部
    handleScroll () {
      if (document.getElementsByClassName('el-main')[0].scrollTop > 200 && this.intemIndex) {
        this.Topshow = true
      } else {
        this.Topshow = false
      }
    },
    toUp () {
      document.getElementsByClassName('el-main')[0].scrollTop = 0
    },
  }
}
</script>
<style lang="less" scoped>
.backtop {
  position: fixed;
  bottom: 50px;
  right: 150px;
  /* background-color: gray; */
}
</style>