<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>审批设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/approvalmodel' }"
        >审批流程</el-breadcrumb-item
      >
      <el-breadcrumb-item>新增/设置流程</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-menu :default-active="activeIndex" mode="horizontal">
        <el-menu-item index="1" @click="Baseinfo">基础信息</el-menu-item>
        <el-menu-item index="2" @click="WorkflowDesign">流程设计</el-menu-item>
        <el-menu-item style="float: right">
          <el-button class="button0" @click="saveDrafts"> 保存草稿</el-button>
          <el-button type="primary" class="button0" @click="savePublish"
            >提交发布</el-button
          >
        </el-menu-item>
      </el-menu>
    </el-card>

    <!--  -->
    <div>
      <div v-if="activeIndex == '1'">
        <el-row :gutter="30" class="row0">
          <el-form
            :model="queryinfo"
            :rules="queryinfoRules"
            ref="queryinfoRef"
            label-width="100px"
          >
            <el-form-item label="流程类型：" class="input1" prop="type">
              <el-input
                v-model.trim="queryinfo.type"
                placeholder="请输入流程类型"
                :disabled="Ifupdate ? true : false"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="流程名称：" class="input1" prop="name">
              <el-input
                v-model.trim="queryinfo.name"
                placeholder="请输入名称"
                clearable
              ></el-input>
              <!-- <span class="span1">请保证输入长度在10字以内</span> -->
            </el-form-item>

            <el-form-item label="流程描述：" prop="note" class="input1">
              <el-input
                type="textarea"
                clearable
                rows="4"
                placeholder="请输入描述"
                v-model.trim="queryinfo.note"
                maxlength="100"
                @input="DesText"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
      <div v-else>
        <el-row :gutter="30" class="row01">
          <div class="zoom">
            <div @click="zoomSize(1)" class="zoom-out"></div>
            <span class="span1">{{ nowVal }}%</span>
            <div @click="zoomSize(2)" class="zoom-in"></div>
          </div>

          <div
            class="box-scale"
            :style="
              'transform: scale(' +
              nowVal / 100 +
              '); transform-origin: 50% 0px 0px;'
            "
          >
            <div class="end-node">
              <p>开始</p>
            </div>
            <WorkFlow :nameValueNull="nameValueNull"></WorkFlow>
            <div class="end-node">
              <p>结束</p>
            </div>
          </div>
        </el-row>
      </div>
    </div>

    <!-- 回到顶部 -->
    <BcakTop :intemIndex="activeIndex"></BcakTop>
  </div>
</template>
<script>
import WorkFlow from '@/components/Home/cloudmarket/approvalmanager/workflow/workflow.vue'
import { getFlowTypeAll, getFlowType, getFlowNodeType, insertFlowNode, insertFlowType, updateFlowType } from '@/api/cloudmarket/apporoval'
import ListenEvent from '@/libs/ListenEvent.js'
import BcakTop from '@/components/myself/el_backtop'

export default {
  components: {
    WorkFlow, BcakTop
  },
  data () {
    return {
      activeIndex: '1',
      queryinfo: {
        name: '',
        note: '',
        type: ''
      },
      // TypeIdOptionKeys: {
      //   value: 'typeKey',
      //   label: 'typeKey',
      //   label2: ''
      // },
      // TypeIdOptions: [],
      // 
      queryinfoRules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 1, max: 20, message: '长度必须在1-20个字符间', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '类型不能为空', trigger: 'blur' },
          { min: 1, max: 20, message: '长度必须在1-20个字符间', trigger: 'blur' }
        ],
      },
      wordnum: 0,
      nameValueNull: '',

      // 流程列表
      FlowList: [],
      ifSave: false,

      // 
      Ifupdate: false,

      // 
      nowVal: 100,

    }
  },

  async mounted () {
    if (this.$route.query.modelApproval === '2') {
      const res = await getFlowType(window.sessionStorage.getItem('modelTypeValue'))
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.queryinfo = res.data.data

      this.Ifupdate = true
    }
    // this.getTypeALL()

    ListenEvent.$on('SaveFlowValue', (para) => {
      para.forEach(item => {
        this.$set(item, 'flowType', this.queryinfo.type)
      });
      this.FlowList = para
    })
  },
  methods: {
    Baseinfo () {
      this.activeIndex = '1'
      this.nameValueNull = ''
    },
    DesText () {
      this.wordnum = this.queryinfo.note.length
    },
    async Typeall () {
      let flag = false
      if (this.$route.query.modelApproval === '1') {
        const res = await getFlowTypeAll()
        if (res.status !== 200) return this.$message.error('获取失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        res.data.data.forEach(item => {
          if (item.type === this.queryinfo.type) {
            flag = true
          }
        })
      }
      return flag
    },

    async WorkflowDesign () {
      this.activeIndex = '2'
      if (!this.queryinfo.name.length || !this.queryinfo.type.length) {
        this.nameValueNull = '1'
      } else {
        if (await this.Typeall()) {
          this.nameValueNull = '2'
        }
      }

      // 传值进去
      if (this.queryinfo.type) {
        if (this.ifSave || this.$route.query.modelApproval === '2') {
          const res1 = await getFlowNodeType(this.queryinfo.type)
          if (res1.status !== 200) return this.$message.error('获取失败')
          if (res1.data.status !== 200) return this.$message.error(res1.data.message)
          this.FlowList = res1.data.data
          ListenEvent.$emit('showFlow', this.FlowList)
        }
      }
    },

    async saveDrafts () {
      if (!this.queryinfo.name.length || !this.queryinfo.type.length) return this.$message.warning('请先设置基础信息')
      if (await this.Typeall()) return this.$message.warning('此流程类型已存在,请重新设置')
      //
      ListenEvent.$emit('SaveFlow')
      if (this.FlowList.length <= 0) return this.$message.warning('请设置流程节点')
      // 

      if (this.$route.query.modelApproval === '1') {
        // 插入流程类型属性,类型唯一
        const res = await insertFlowType(this.queryinfo)
        if (res.status !== 200) return this.$message.error('保存基础信息失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
      } else {
        //  修改流程类型属性
        const res = await updateFlowType(this.queryinfo)
        if (res.status !== 200) return this.$message.error('修改基础信息失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
      }

      // 修改流程
      const res1 = await insertFlowNode(this.FlowList)
      if (res1.status !== 200) return this.$message.error('修改流程设计失败')
      if (res1.data.status !== 200) return this.$message.error(res1.data.message)


      this.$message.success('保存草稿成功')

      this.ifSave = true
      this.FlowList = []
    },
    savePublish () { },


    // 
    zoomSize (type) {
      if (type == 1) {
        if (this.nowVal == 50) {
          return;
        }
        this.nowVal -= 10;
      } else {
        if (this.nowVal == 300) {
          return;
        }
        this.nowVal += 10;
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-menu-item {
  font-size: 16px;
  padding: 0 50px;
}

.button0 {
  font-size: 16px;
}
.row0 {
  margin-top: 30px;
  left: 30%;
}
.row01 {
  margin-top: 30px;
  left: 40%;
}
.input1 {
  width: 40%;
}
.span1 {
  float: left;
  font-size: 10px;
}

.box-scale {
  transform: scale(1);
  width: 280px;
}
.DivF {
  height: 100%;
}
.end-node {
  justify-content: center;
  flex-direction: column;

  p {
    // width: 280px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    background-color: #8cafff;
    border-radius: 4px;
    font-size: 14px;
  }
}
.zoom {
  position: fixed;
  height: 40px;
  width: 125px;
  right: 150px;
  margin-top: 30px;
  z-index: 10;
  display: flex;
  .zoom-in,
  .zoom-out {
    background: #fff;
    color: #c1c1cd;
    cursor: pointer;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .zoom-out {
    background-image: url(https://gw.alicdn.com/tfs/TB1s0qhBHGYBuNjy0FoXXciBFXa-90-90.png);
    width: 30px;
    height: 30px;
  }

  .zoom-in {
    background-image: url(https://gw.alicdn.com/tfs/TB1UIgJBTtYBeNjy1XdXXXXyVXa-90-90.png);
    width: 30px;
    height: 30px;
  }
  .span1 {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>