<template>
  <div>
    <div class="end-steps">
      <el-popover
        placement="right-start"
        popper-class="add-node-popover"
        v-model="visible"
      >
        <div class="add-node-popover-body">
          <div class="add-node-popover-item approver" @click="addType(1)">
            <div class="item-wrapper">
              <img src="@/assets/imgs/审批人.png" alt="" class="img-style" />
            </div>
            <p>审批人</p>
          </div>
          <div class="add-node-popover-item notifier" @click="addType(2)">
            <div class="item-wrapper">
              <img src="@/assets/imgs/抄送人.png" alt="" class="img-style" />
            </div>
            <p>抄送人</p>
          </div>
          <div class="add-node-popover-item condition" @click="addType(3)">
            <div class="item-wrapper">
              <img src="@/assets/imgs/条件.png" alt="" class="img-style" />
            </div>
            <p>条件分支</p>
          </div>
        </div>
        <el-button
          type="text"
          slot="reference"
          icon="el-icon-circle-plus"
          class="button0"
          @click="nameClick(nameValueNull)"
        >
        </el-button>
      </el-popover>
    </div>
  </div>
</template>
<script>
import ListenEvent from '@/libs/ListenEvent.js'

export default {
  props: {
    childNodeIndex: {
      type: Number,
      default: 0
    },
    nameValueNull: {
      type: String,Boolean
    },
  },
  data () {
    return {
      visible: false,
    }
  },

  methods: {
    addType (para) {
      this.visible = false
      let NodeName = ''
      if (para === 1) {
        NodeName = '审批人'
      } else if (para === 2) {
        NodeName = '抄送人'
      } else {
        NodeName = '条件分支'
      }
      ListenEvent.$emit('addTypeValue', [NodeName, this.childNodeIndex, para])
    },
    nameClick (para) {
      // console.log('para', para)
      if (para==='1') {
        this.visible = true
        return this.$message.warning('请先设置基础信息')
      } else if (para === '2') {
        this.visible = true
        return this.$message.warning('此流程类型已存在,请重新设置')
      }
    }

  }
}
</script>
<style lang="less" scoped>
.end-steps {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 0;
}
.end-steps::before {
  content: "";
  position: absolute;
  // top: 50%;
  left: 140px;
  width: 2px;
  height: 100px;
  background: #e9e9ec;
  z-index: -3;
}
.button0 {
  font-size: 30px;
  margin-left: 125px;
  margin-top: 20px;
  border: none;
}

// popover
.img-style {
  width: 36px;
}

.add-node-popover {
  padding: 14px 26px;
  .add-node-popover-body {
    display: flex;
    .add-node-popover-item {
      margin-right: 20px;
      text-align: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      i {
        font-size: 36px;
      }
      p {
        color: #333;
        margin-top: 4px;
      }
    }
    .approver {
      i {
        color: #e6a23c;
      }
    }
    .condition {
      pointer-events: none;
      i {
        color: #67c23a;
      }
    }
    .notifier {
      pointer-events: none;
      i {
        color: #4880ff;
      }
    }
  }
}
</style>